import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput;

$(document).ready(on_party_page_load)

function on_party_page_load() {
  $('#party-date').on("focus", function (evt) {
    if (this.getAttribute("type") === "date") {
      this.showPicker();
    }
  });
  
  $('#party-time').on("focus", function (evt) {
    if (this.getAttribute("type") === "time") {
      this.showPicker();
    }
  });
  
  // <!-- Party Location Table Hide and Show -->
  function togglePartyLocation() {
    if ($('#partyToggle').is(':checked')) {
      $('#party-table').show()
    } else {
      $('#party-table').hide();
    }
  }
  
  $('#partyToggle').on('change', togglePartyLocation);
  
  togglePartyLocation();
  
  // <!-- Shipping Location Hide and Show -->
  let shippingToggle = $('#shippingToggle');
  let shippingLocation = $('#shipping-table');
  
  function toggleShippingLocation() {
    if (shippingToggle.is(':checked')) {
      shippingLocation.show();
    } else {
      shippingLocation.hide();
    }
  }
  
  shippingToggle.on('change', toggleShippingLocation);
  
  toggleShippingLocation();
  
  // <!-- DPD Location Hide and Show -->
  let dpdToggle = $('#dpdToggle');
  let dpdLocation = $('#dpd-table');

  function toggleDPDLocation() {
    if (dpdToggle.is(':checked')) {
      dpdLocation.show();
      shippingToggle.prop('checked', false);
      shippingToggle.prop('disabled', true);
      shippingLocation.hide();
      $('#partyToggle').prop('checked', false);
      $('#partyToggle').prop('disabled', true);
      $('#party-table').hide();
    } else {
      dpdLocation.hide();
      shippingToggle.prop('disabled', false);
      $('#partyToggle').prop('disabled', false);      
    }
  }

  dpdToggle.on('change', toggleDPDLocation);

  toggleDPDLocation();
  
  // <!-- Host search method radio -->
  const consultantAsHostRadio = $('#consultant_as_host');
  const searchAddHostRadio = $('#search_add_host');
  const searchAddHostForm = $('#search_add_host_form');
  const hostDetailsForm = $('#host_details_form');
  
  function toggleAddHostForm() {
    if (searchAddHostRadio.is(':checked')) {
      searchAddHostForm.show()
    } else {
      searchAddHostForm.hide()
    }
    if (!searchAddHostRadio.is(':checked') && !consultantAsHostRadio.is(':checked')) {
      hostDetailsForm.hide()
    } else {
      hostDetailsForm.show()
    }
  }
  
  consultantAsHostRadio.on('change', toggleAddHostForm);
  searchAddHostRadio.on('change', toggleAddHostForm);
  
  toggleAddHostForm();

  window.initializeTelInput = function() {
    var contact = document.querySelector("input[type='tel']");
    if (contact) {
      var iti = intlTelInput(contact, {
        initialCountry: "auto",
        geoIpLookup: function(success, failure) {
          fetch("https://ipapi.co/json")
            .then(function(res) { return res.json(); })
            .then(function(data) { success(data.country_code); })
            .catch(function() { failure(); });
        },
        separateDialCode: true,
        hiddenInput: "contact_number",
        autoPlaceholder: "off",
        formatAsYouType: false,
        formatOnDisplay: false,      
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js"
      });
    }    
  }

  window.initializeTelInput();

  // This assumes & hence works only when there is only one tel input on current page
  $(document).on('click', '.update-contact-info', function(e) {
    var contact = document.querySelector("input[type='tel']");
    var iti = window.intlTelInputGlobals.getInstance(contact);
    var country_info = iti.getSelectedCountryData();
    document.querySelector("#dial_code").value = country_info.dialCode;
    document.querySelector("#contact_country_iso2").value = country_info.iso2;
    document.querySelector("#formatted_contact_number").value = "+" + country_info.dialCode + contact.value;
    
    if (dpdToggle.is(':checked') && $("#party_dpd_address_pickup_point").val() == "") {
      window.showNotification('error', window.dpd_not_selected_message);
      e.preventDefault();
      e.stopPropagation();
    }
  });

  $('.timepicker').timepicker({
    timeFormat: 'h:mm p',
    interval: 5,
    dynamic: false,
    dropdown: true,
    scrollbar: true,
    zindex: 10000
  });
  
  $(".saveInviteBtn").on('click', function(e) {
    window.saveLangContent();
  });  
 
}

$(".copyToClipboard").on('click', function(event) {
  event.preventDefault();
  const valueToCopy = $(this).data("value-to-copy");
  const successMessage = $(this).data("success-message");
  window.copyToClipboard(valueToCopy);
  window.showNotification('success', successMessage);
});

$("#toggleNewGuestForm").on("click", ({currentTarget}) => {
  const newGuestForm = $("#newGuestForm");
  if (newGuestForm.is(':visible') === false) {
    newGuestForm.show();
    $(currentTarget).removeClass("rotate-3d");
  } else {
    newGuestForm.hide();
    $(currentTarget).addClass("rotate-3d");
  }
});

$("#checkbox-all").on('click', function () {
  $("input[type=checkbox]").prop("checked", $(this).prop("checked"));
});

$("input[type=checkbox]").on('click', function () {
  if (!$(this).prop("checked")) {
    $("#checkbox-all").prop("checked", false);
  }
});

$('.party-location-checkbox').on('change', function() {
  if (this.checked) {
    $('.party-location').hide();
  } else {
    $('.party-location').show();
  }
});

$("#editSubjectBtn").on("click", function () {
  $("#inviteSubjectInput").show();
  $("#inviteSubjectBox").hide();
  $(".saveInviteBtn").show();
  $("#back_to_party").addClass("hide");
  $("#back_to_preview").show();
  $("#invite_actions").addClass("hide");
  $("#save_actions").show();  
});

$("#editContentBtn").on("click", function () {
  $("#inviteTextAreaWrapper").show();
  $("#inviteContentBox").hide();
  $(".saveInviteBtn").show();
  $("#back_to_party").addClass("hide");
  $("#back_to_preview").show();
  $("#invite_actions").addClass("hide");
  $("#save_actions").show();  
});

$('.img-checkbox').on('change', function() {
  $('.img-checkbox').not(this).prop('checked', false);
});

$('.gallery-single-image').on('click', function() {
  $('.img-checkbox').prop('checked', false);
  $(this).find('.img-checkbox').prop('checked', true);
});

$("#applyImageBtn").on("click", function () {
  let selectedImageSrc = null;
  let checkbox = $('.img-checkbox:checked')[0];
  let selectedImageId = checkbox.dataset.imageId;
  if (checkbox) {
    const parentDiv = checkbox.closest('.col-sm-6');
    selectedImageSrc = parentDiv.querySelector('.select-img').src;
    if (selectedImageSrc) {
      bannerImg.src = selectedImageSrc;
      $("#invite_banner_image_id").val(selectedImageId);
    }
  }
  $("#own_banner_image").val('');
  $("#invite_actions").addClass("hide");
  $("#save_actions").show();
});

$("#own_banner_image").on('change', function(event) {
  var input = event.target;
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      bannerImg.src = e.target.result;
    }
    reader.readAsDataURL(input.files[0]);
  }

  $("#invite_banner_image_id").val('');
  $("#invite_actions").addClass("hide");
  $("#save_actions").show();  
});

$("#guests-invite").on("click", function() {
  let inviteLink = $(this).data('invite-link');
  let errorMessage = $(this).data('error-message');
  const concat_str = inviteLink.includes("?") ? "&" : "?"
  inviteLink = inviteLink + concat_str;

  const all_guests = $("#checkbox-all").is(":checked");
  if (all_guests) {
    window.location = inviteLink + "guests_list=all"
  } else {
    const guest_ids = $(".guest-cb:checked").map(function() {return $(this).data("user-id")}).get();
    if (guest_ids.length) {
      window.location = inviteLink + "guests_list=" + guest_ids.join()
    } else {
      window.showNotification('error', errorMessage);
    }
  }
});

window.saveLangContent = function(new_lang_code) {
  const invite_editor = tinymce.get('inviteTextArea');
  // save previous lang content
  let current_lang_code = invite_editor.editorCommands.editor.options.get("language");
  $(`#invite_content_${current_lang_code}`).val(invite_editor.getContent());
  $("#invite_lang").val(current_lang_code);
  
  // load currently set lang content
  if (new_lang_code != undefined) {
    invite_editor.editorCommands.editor.remove();
    
    window.invite_tinymce_options.language = new_lang_code;
    let lang_content = $(`#invite_content_${new_lang_code}`).val();
    $("#inviteTextArea").val(lang_content);

    tinymce.init(window.invite_tinymce_options);
  }
}

