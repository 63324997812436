import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

var map;

export default class extends Controller {

  connect(){
    console.log("order controller connect");
  }

  show_product_detail(event) {
    let product = JSON.parse(event.target.dataset.product);
    let orderId = event.target.dataset.orderId;
    let orderType = event.target.dataset.orderType;
    let rating = Math.round(parseFloat(product["rating"]));

    if (product["image_url"]) {
      $("#host-product-img").attr("src", product["image_url"]);
    }

    $("#product-detail-rating")
      .children()
      .each((i, el) => {
        if (rating > 0) {
          el.children[0].classList.remove("unstar");
        } else {
          el.children[0].classList.add("unstar");
        }
        rating = rating - 1;
      });
    $("#product-sku-id").text(`SKU ID: ${product["sku"]}`);
    $("#product-detail-title").text(product["name"]);
    $("#product-detail-description").text(product["description"]);
    $("#product-detail-price").text(product["price_value"]);
    $("#product-detail-quantity").val("1");
    $("#product-detail-button").attr("data-order-id", orderId);
    $("#product-detail-button").attr("data-order-type", orderType);
    $("#product-detail-button").attr("data-product-id", product["id"]);

    if (orderType == "host") {
      if ($("#selected_promotion_id")) {
        let promotion_id = $("#selected_promotion_id").val();
        let promotion_type = $("#selected_promotion_name").val();

        $("#product-detail-button").attr("data-promotion-id", promotion_id);
        $("#product-detail-button").attr("data-promotion-name", promotion_type);
        $("#product-detail-button").attr("data-special", "true");
      } else {
        $("#product-detail-button").attr("data-promotion-id", "");
        $("#product-detail-button").attr("data-promotion-name", "");
        $("#product-detail-button").attr("data-special", "false");
      }
    }
  }

  async response_message(response){
    try {
      if(!response.ok){
        let body = await response.json;
        window.showNotification('error', body.body);
      }
    } catch (error) {
      console.log(response);
    }
  }

  async get_convertable_gift_certificates(event) {
    let dataSet = event.target.dataset;
    if(event.target.nodeName == "BUTTON"){
      dataSet = event.target.dataset;
    } else {
      dataSet = event.target.parentElement.dataset;
    }
    await get(`/parties/${dataSet.partyId}/host/orders/${dataSet.orderId}/get_convertable_certificates`);
  }

  async get_redeemable_gift_certificates(event) {
    let dataSet = event.target.dataset;
    await get(`/parties/${dataSet.partyId}/host/orders/${dataSet.orderId}/get_redeemable_certificates`);
  }

  async delete_gift_certificate(event){
    let dataSet = event.target.dataset;
    let id = dataSet.id;
    let url = `/parties/${dataSet.partyId}/host/orders/${dataSet.orderId}/delete_convertable_certificates`;
    let response = await post(url, { responseKind: "turbo-stream", body: { ids: [id] } });
    if(response.ok){
      window.showNotification('success', window.gift_card_delete_success_message);
    } else {
      this.response_message(response);
    }
  }

  async update_convertable_gift_certificates(event){
    let dataSet = event.target.dataset;
    let amount = $("#convertable-gift-card-value").val();
    if(parseInt(amount) >= 10){
      let gift_card = { amount };
      let url = `/parties/${dataSet.partyId}/host/orders/${dataSet.orderId}/update_convertable_certificates`;
      let response = await post(url, { responseKind: "turbo-stream", body: { gift_card } });
      if(response.ok){
        window.showNotification('success', window.gift_card_converted_success_message);
      } else {
        this.response_message(response);
      }
    } else {
      window.showNotification('error', window.gift_card_minimum_required_amount_message);
    }
  }

  async pay_with_worldpay_card(event){
    let params = "width=800,height=800,left=100,top=100";
    let newWindow = window.open("", "worldpay_payment_page", params);
    newWindow.focus();
    newWindow.onload = function() {
      
    };
    
    let dataSet = event.target.dataset;
    let party_id = dataSet.partyId;
    let paymentmethod = dataSet.paymentmethod;
    let order_ids = null;
    if(dataSet.orderIds){
      order_ids = JSON.parse(dataSet.orderIds);
    }
    let user_id = dataSet.userId;
    let url = `/payments/authorize_orders_worldpay_popup`;
    let response = await post(url, { body: { party_id, paymentmethod, user_id, order_ids } });
    try {
      if(!response.ok){
        let body = await response.json;
        window.showNotification('error', body.body);
        newWindow.close();
      } else {
        let body = await response.json;
        if(body.success && body.payment_complete) {
          newWindow.close();
        } else if (body.success && body.payment_page_url) {
          newWindow.location.href = body.payment_page_url;
        }
      }
    } catch (error) {
      if(newWindow){
        newWindow.close();
      }
      console.log(error);
      console.log(response);
    }
  }

  async pay_with_card(event){
    let dataSet = event.target.dataset;
    let party_id = dataSet.partyId;
    let paymentmethod = dataSet.paymentmethod;
    let order_ids = null;
    if(dataSet.orderIds){
      order_ids = JSON.parse(dataSet.orderIds);
    }
    let user_id = dataSet.userId;
    let url = `/payments/authorize_orders_popup`;
    let response = await post(url, { responseKind: "turbo-stream", body: { party_id, paymentmethod, user_id, order_ids } });
  }

  getUrlBasedOnOrderType(urlPart){
    if(window.location.href.includes("/host")){
      return `${window.location.href.split("?")[0]}/${urlPart}`;
    } else {
      return urlPart;
    }
  }

  async removePickupPoint(){
    let url = this.getUrlBasedOnOrderType("remove_pickup_location");
    let response = await post(url,{ responseKind: "turbo-stream" });
    if(response.ok){
    } else{
      this.response_message(response);
    }
  }
  
  async initializeMap() {
    if(map){
      
    } else {
      let url = this.getUrlBasedOnOrderType("get_user_location");
      let response = await get(url);
      let body = await response.json;
      map = new google.maps.Map(document.getElementById("map"), {
        center: body,
        zoom: 8,
      });

      let pickupPointUrl = this.getUrlBasedOnOrderType("load_dpd_pickup_points");
      let resp = await get(pickupPointUrl);
      let pickupPoints = await resp.json;
      if(pickupPoints && pickupPoints.length > 0){
        pickupPoints.forEach((point) => {
          let marker = new google.maps.Marker({
            position: new google.maps.LatLng(point.latitude, point.longitude),
            map: map,
          });
  
          marker.addListener('click', function () {
            let fullAddress = "";
            if(point.house_no){
              fullAddress = fullAddress.concat(`${point.house_no}, `);
            }
            if(point.street){
              fullAddress = fullAddress.concat(`${point.street}, `);
            }
            if(point.town){
              fullAddress = fullAddress.concat(`${point.town}, `);
            }
            if(point.city){
              fullAddress = fullAddress.concat(`${point.city}, `);
            }
            if(point.country){
              fullAddress = fullAddress.concat(`${point.country}, `);
            }
            if(point.zip_code){
              fullAddress = fullAddress.concat(`${point.zip_code}, `);
            }
            $("#dpdLocationHoursButton").attr("data-hours", JSON.stringify(point.opening_hours));
            $("#dpdShippingLocation").show();
            $("#dpdShippingLocationAddress").html(fullAddress);
            $("#dpdShippingLocationButton").attr("data-dpd-address-point", JSON.stringify(point));
          });
        });
      } else {
        window.showNotification('error', window.empty_dpd_pickup_points);
      }
    }
    
  }

  setOpeningTimings(event){
    let dataSet = event.target.dataset;

    if(event.target.nodeName == "H6"){
      dataSet = event.target.dataset;
    } else {
      dataSet = event.target.parentElement.dataset;
    }

    let hours = JSON.parse(dataSet.hours);
    let openingHours = "";
    let days = "";
    let morningRow = "";
    let eveninggRow = "";
    if(hours){
      days = days.concat("<tr>");
      morningRow = morningRow.concat("<tr>");
      eveninggRow = eveninggRow.concat("<tr>");
      hours.forEach((o) => {
        days = days.concat(`<th>${o.weekday || "-"}</th>`);
        if(o.open_morning !== o.close_morning){
          morningRow = morningRow.concat(`<td>${o.open_morning} - ${o.close_morning}</td>`);
        } else {
          morningRow = morningRow.concat(`<td>-</td>`);
        }

        if(o.open_afternoon !== o.close_afternoon){
          eveninggRow = eveninggRow.concat(`<td>${o.open_afternoon} - ${o.close_afternoon}</td>`);
        } else {
          eveninggRow = eveninggRow.concat(`<td>-</td>`);
        }
      });
      eveninggRow = eveninggRow.concat("</tr>");
      morningRow = morningRow.concat("</tr>");
      days = days.concat("</tr>");
    }
    openingHours = openingHours.concat(days, morningRow, eveninggRow);
    $("#openingHoursData").html(openingHours);
  }

  async saveDpdAddress(event){
    let dataSet = event.target.dataset;
    let pickupPoint = JSON.parse(dataSet.dpdAddressPoint);
    if(pickupPoint){
      let url = this.getUrlBasedOnOrderType("save_dpd_point");
      let response = await post(url, { responseKind: "turbo-stream", body: { city: pickupPoint.city, company: pickupPoint.company, country: pickupPoint.country, house_no: pickupPoint.house_no, latitude: pickupPoint.latitude, longitude: pickupPoint.longitude, parcel_shop_id: pickupPoint.parcel_shop_id, street: pickupPoint.street, town: pickupPoint.town, zip_code: pickupPoint.zip_code, opening_hours: JSON.stringify(pickupPoint.opening_hours) } });
      if(response.ok){
        let respBody = await response.json;
        let fullAddress = "";
        if(pickupPoint.house_no){
          fullAddress = fullAddress.concat(`${pickupPoint.house_no}, `);
        }
        if(pickupPoint.street){
          fullAddress = fullAddress.concat(`${pickupPoint.street}, `);
        }
        if(pickupPoint.town){
          fullAddress = fullAddress.concat(`${pickupPoint.town}, `);
        }
        if(pickupPoint.city){
          fullAddress = fullAddress.concat(`${pickupPoint.city}, `);
        }
        if(pickupPoint.country){
          fullAddress = fullAddress.concat(`${pickupPoint.country}, `);
        }
        if(pickupPoint.zip_code){
          fullAddress = fullAddress.concat(`${pickupPoint.zip_code}, `);
        }
        $("#dpdLocationHours1Button").attr("data-hours", JSON.stringify(pickupPoint.opening_hours));
        $("#dpd-Pickup-Location-container").show();
        $("#dpdShippingAddressLocation").html(fullAddress);
        $("#order_dpd_address_pickup_point").val(respBody.id);
      } else {
        this.response_message(response);
      }
    } else if($("#order_dpd_address_pickup_point").val()){
      
    } else {
      $("#toggleDpdPickUpLocationCheckbox").prop('checked', false);
      $("#order_dpd_address_pickup_point").val("");
      $("#dpd-Pickup-Location-container").hide();
    }
  }

  closingLocationModal(){
    if(document.getElementById("toggleDpdPickUpLocationCheckbox").checked){
      if($("#order_dpd_address_pickup_point").val()){

      }else {
        document.getElementById("toggleDpdPickUpLocationCheckbox").checked = false;
        $("#dpd-Pickup-Location-container").hide();
      }
    }
  }

  get_gift_cards(){
    let list = document.getElementById("redeemable-certificates-body");
    let gift_certificates = [];
    let gift_certificate_products = [];
    let gift_certificates_amount = 0;
    if(list){
      let cards = list.children;
      for (let i = 0; i < cards.length; i++) {
        let card = cards[i];
        let child = card.querySelector("[name='gift_certificate[][id]']");
        if(child){
          let checked = card.querySelector("[name='gift_certificate[][checked]']").checked;
          if(checked) {
            let type = card.querySelector("[name='gift_certificate[][type]']").value;
            gift_certificates_amount += parseFloat(card.querySelector("[name='gift_certificate[][amount]']").value);
            let gift_card = {};
            gift_card["id"] = child.value;
            if(type == "GiftCertificate"){
              gift_certificates.push(gift_card);
            } else {
              gift_certificate_products.push(gift_card);
            }
            
          }
        }
      }
    }
    return { gift_certificates, gift_certificate_products, gift_certificates_amount };
  }

  update_redeemable_gift_certificates_total(event){
    let { gift_certificates_amount } = this.get_gift_cards();
    gift_certificates_amount = Math.round((gift_certificates_amount + Number.EPSILON) * 100) / 100;
    console.log("gift_certificates_amount", gift_certificates_amount);
    let currency = document.getElementById('redeem-gift-card-currency').value;
    document.getElementById('redeem-gift-card-total').innerText = `${currency}${gift_certificates_amount.toFixed(2)}`;
  }

  async update_redeemable_gift_certificates(event){
    let dataSet = event.target.dataset;

    let url = `/parties/${dataSet.partyId}/host/orders/${dataSet.orderId}/update_redeemable_certificates`;
    let { gift_certificates, gift_certificate_products } = this.get_gift_cards();
    let response = await post(url, { responseKind: "turbo-stream", body: { gift_certificates, gift_certificate_products } });
    if(response.ok){
      window.showNotification('success', window.gift_card_redeemed_success_message);
      location.reload();
    } else {
      this.response_message(response);
    }
  }

  async create_redeemable_gift_certificates(event){
    let dataSet = event.target.dataset;

    let url = `/parties/${dataSet.partyId}/host/orders/${dataSet.orderId}/update_redeemable_certificates`;
    let { gift_certificates, gift_certificate_products } = this.get_gift_cards();
    let certificate_product = {};
    certificate_product["certificate_number"] =  document.getElementById("new-gift-certificate-number").value;
    if(certificate_product["certificate_number"])
    {
      gift_certificate_products.push(certificate_product);
      let response = await post(url, { responseKind: "turbo-stream", body: { gift_certificates, gift_certificate_products } });
      if(response.ok){
        window.showNotification('success', window.gift_card_redeemed_success_message);
        location.reload();
      } else {
        this.response_message(response);
      }
    }else{
      window.showNotification('error', window.invalid_gift_card_number_message);
    }
  }

  show_host_product_detail(event) {
    let product = JSON.parse(event.target.dataset.product);
    let promotion = JSON.parse(event.target.dataset.promotion);
    let orderId = event.target.dataset.orderId;
    let orderType = event.target.dataset.orderType;
    let currency = event.target.dataset.currency;
    let special = event.target.dataset.special;
    let rating = Math.round(parseFloat(product["rating"]));

    $("#product-detail-rating")
      .children()
      .each((i, el) => {
        if (rating > 0) {
          el.children[0].classList.remove("unstar");
        } else {
          el.children[0].classList.add("unstar");
        }
        rating = rating - 1;
      });
    $("#product-detail-title").text(product["name"]);
    $("#product-detail-description").text(product["description"]);
    $("#product-detail-price").text(product["price_value"]);
    $("#product-detail-quantity").val("1");
    $("#product-detail-button").attr("data-order-id", orderId);
    $("#product-detail-button").attr("data-order-type", orderType);
    $("#product-detail-button").attr("data-product-id", product["id"]);
    $("#product-detail-button").attr("data-currency", currency);
    $("#product-detail-button").attr("data-special", special);
    $("#product-detail-button").attr("data-promotion-id", promotion["id"]);
    $("#product-detail-button").attr("data-promotion-name", promotion["name"]);
  }
}
