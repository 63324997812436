import { Notyf } from 'notyf';

window.showNotification = function(type, message) {
  window.notyf = window.notyf || new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    types: [
      {
        type: 'success',
        background: 'rgba(139, 186, 130, 0.9)',
        duration: 4000,
      },
      {
        type: 'error',
        background: 'rgba(167, 83, 74, 0.9)',
        duration: 4000,
      },      
    ],
  });

  const notificationMessage = `<span>${message}</span>`;

  window.notyf.open({
    type: type,
    message: notificationMessage,
    duration: 4000,
    className: 'custom-notification',
  });
}

window.copyToClipboard = function(valueToCopy) {
  if (navigator.clipboard != undefined) {
    navigator.clipboard.writeText(valueToCopy);
  } else {
    var input = document.createElement('textarea');
    input.innerHTML = valueToCopy;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  }
}

$(".timepicker").on("focus touchstart", function(e) {
  $(".timepicker").attr("readonly", "readyonly");
})
$(".timepicker").on("blur", function(e) {
  $(".timepicker").removeAttr("readonly")
})

window.setupAddressValidation = function(form_id) {
  const form = document.querySelector(form_id)
  
  if (form == null) {
    return;
  }

  const current_country_id = $(`${form_id} .country`).val();
  if (!current_country_id) {
    return
  }

  var auth_token = (current_country_id == window.usa_country_id) ? window.experian_us_auth_token : window.experian_non_us_auth_token

  const options = {
    token: auth_token,
    countryCodeMapping : window.countryMap,
    maxSuggestions: 10,
    useSpinner: true,
    input: { applyFocus: true },
    searchAgain: { visible: false },
    elements: {
        inputs: [form.querySelector('.address1')],
        countryList: form.querySelector('.country'),
        address_line_1: form.querySelector('.address1'),
        address_line_2: form.querySelector('.address2'),
        locality: form.querySelector('.city'),
        region: form.querySelector('.state'),
        postal_code: form.querySelector('.zip')
    }
  };

  var address = new AddressValidation(options);
  address.events.on("post-formatting-search", function(data) { 
    form.querySelector('.state').value = window.states_data[data.result.address.region];
    var address2 = [data.result.address.address_line_2, data.result.address.address_line_3].filter(item => item).join(", ");
    form.querySelector('.address2').value = address2;
  });
  address.events.on("pre-formatting-search", function(data) { 
    form.querySelector('.address2').value = '';
    form.querySelector('.city').value = '';
    form.querySelector('.zip').value = '';
  });  
  address.events.on("post-reset", function() { 
    form.querySelector('.state').value = '';
  });
  
  $(`${form_id} .address1`).on('keyup', function(e) {
    if (form.querySelector('.country').value == "") {
      window.showNotification("error", "please select a country before start searching..")
    }
  });
}

$(document).on('change','.country', function(e) {
  var parent_ele = $(e.target).parents('.experian_address_wrapper');
  if (parent_ele.length == 1) {
    var target_id = '#' + parent_ele.attr('id');
    window.setupAddressValidation(target_id);
  }
});

$(document).on('keyup', '.address1', function(e) {
  var parent_ele = $(e.target).parents('.experian_address_wrapper');
  if (parent_ele.length == 1) {
    var target_id = '#' + parent_ele.attr('id');
    var current_country_id = $(`${target_id} .country`).val();
    if (!current_country_id) {
      window.showNotification("error", "please select a country before start searching..")
    }
  }
});
